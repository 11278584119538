import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BottomErrMsg, Popup } from "component/basic/popup";
import { ContentsSection, InputSection } from "component/app/items";
import { InputItemBox } from "component/basic/formItems";
import { BtnItem } from "component/basic/btns";
import { SnsBtn } from "component/member/items";
import { getParam } from "js/function";

const Login = (props) => {
    const varUA = navigator.userAgent;
    const navigate = useNavigate();
    const type = getParam("type");

    const [id,setId] =  useState("");
    const [pw,setPw] =  useState("");

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [inputErr,setInputErr] =  useState(false);

    function loginStart(){
        let formData = new FormData();
        formData.append("email", id);
        formData.append("password", pw);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/login",
            success: (data) => {
                localStorage.setItem("token",data.data.access_token);
                localStorage.setItem("refresh_token",data.data.refresh_token);

                window.logIn();

                if(data.data.is_check_password === 1){
                    if(data.data.company_count === 1){
                        localStorage.setItem("companyId",data.data.company_id);
                        navigate("/workHistory");
                    }else{
                        navigate(`/member/company`);
                    }
                }else{
                    navigate(`/member/pwSetting`);
                }
            },
            err: (data) => {
                if(data.data || data.alert){
                    setInputErr(true);
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    //카카오
    function kakaoLoginStart() {
        if (varUA.match("waveone/Android") != null) {
            window.waveone.kakaoLoginCheck('login')
        } else if (varUA.match("waveone/iOS") != null) {
            window.webkit.messageHandlers.kakaoLoginCheckHandler.postMessage("login");
        } else {
            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code`;
        }
    }

    //애플
    function appleLoginStart(){
        window.location.href = `https://appleid.apple.com/auth/authorize?client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_APPLE_REDIRECT_URL}`;
    }

    useEffect(() => {
        if(type){
            let formData = new FormData();
            if(type === "kakao" || type === "apple"){
                formData.append("code", getParam("code"));
            }

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/${type}/login`,
                success: (data) => {
                    localStorage.setItem("token",data.data.access_token);
                    localStorage.setItem("refresh_token",data.data.refresh_token);

                    window.logIn();

                    if(data.data.is_check_password === 1){
                        if(data.data.company_count === 1){
                            localStorage.setItem("companyId",data.data.company_id);
                            navigate("/workHistory");
                        }else{
                            navigate(`/member/company`);
                        }
                    }else{
                        navigate(`/member/pwSetting`);
                    }
                },
                err: (data) => {
                    if(data.data || data.alert){
                        setBottomMsgData({
                            text : data.alert||"",
                            chk : bottomMsgData.chk + 1
                        });
                    }
                }
            })
        }
    }, []);

    return (
        <>
            <ContentsSection addClass="">
                <div className="loginContetns">
                    <div className="loginSection">
                        <div className="logoBox">
                            <img src="/assets/images/app/logo.svg"/>
                        </div>
                        <InputItemBox
                            inputType={null}
                            addClass={inputErr ? "err" : ""}
                            inputName="이메일 주소"
                            placeholder="이메일 주소"
                            value={id}
                            max={null}
                            regexp={null}
                            func={(value)=>{setId(value)}}
                        />
                        <InputItemBox
                            inputType="password"
                            boxAddClass="m-0"
                            addClass={(inputErr ? "err" : "")}
                            inputName="비밀번호"
                            placeholder="비밀번호 입력"
                            pwChType={true}
                            value={pw}
                            max={null}
                            regexp={null}
                            func={(value)=>{setPw(value)}}
                        />
                    </div>
                    <div className="loginSection grip-gap">

                        <a className={"loginSection findPw"} href="/member/find/password">비밀번호 찾기</a>
                        <BtnItem
                            addClass="subType"
                            contents="로그인"
                            func={() => {loginStart()}}
                        />
                        <SnsBtn
                            img="kakao"
                            text="카카오로 로그인"
                            func={() => {kakaoLoginStart()}}
                        />
                        <SnsBtn
                            img="apple"
                            text="Apple 로그인"
                            func={() => {appleLoginStart()}}
                        />
                    </div>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Login;