import Main from "pages/main/Main";
import Company from "pages/member/Company";
import Login from "pages/member/Login";
import PwSetting from "pages/member/PwSetting";
import PublicOnly from "pages/private/publicOnly";
import WorkHistory from "pages/workHistory/Main";
import WorkHistoryModifyRequest from "pages/workHistory/ModifyRequest";
import WorkHistoryModifyDetail from "pages/workHistory/ModifyDetail";
import Additional from "pages/additional/Main";
import AdditionalRequest from "pages/additional/Request";
import AdditionalRequestDetail from "pages/additional/RequestDetail";
import DayOff from "pages/dayoff/Main";
import DayOffRequest from "pages/dayoff/Request";
import DayOffDetail from "pages/dayoff/Detail";
import TempWorker from "pages/tempWorker/Main";
import TempWorkerRegister from "pages/tempWorker/Register";
import TempWorkerModify from "pages/tempWorker/Modify";
import Salary from "pages/salary/Main";
import SalaryDetail from "pages/salary/Detail";
import Board from "pages/board/Index";
import BoardModify from "pages/board/Modify";
import BoardDetail from "pages/board/Detail";
import BoardRegister from "pages/board/Register";
import Workflow from "pages/workflow/Main";
import Todo from "pages/todo/Main";
import TodoRegister from "pages/todo/Register";
import TodoModify from "pages/todo/Modify";
import Document from "pages/document/Main";
import DocumentRequest from "pages/document/Request";
import DocumentDetail from "pages/document/Detail";
import Mypage from "pages/mypage/Index";
import PwModify from "pages/mypage/PwModfiy";
import React from "react";
import { Navigate } from "react-router-dom";
import DocumentSign from "pages/document/Sign";
import ScheduleMain from "pages/schedule/Main";
import SetPassword from "../pages/member/SetPassword";
import PwFind from "../pages/member/PwFind";

//type
//login : 로그인 한정 페이지
// notLogin : 비로그인 한정 페이지
// null : 로그인,비로그인 무관 페이지

export const routes = [
    {
        exact: true,
        path: "/pageErr",
        component: <PublicOnly/>,
        type : null
    },
    {
        exact: true,
        path: "/pageErr/:err",
        component: <PublicOnly/>,
        type : null
    },
    {
        exact: true,
        path: "/",
        component: <Navigate to="/main" />,
        type : null
    },
    {
        exact: true,
        path: "/main",
        component: <Main/>,
        type : null
    },
    {
        exact: true,
        path: "/member/login",
        component: <Login/>,
        type : "notLogin"
    },
    {
        exact: true,
        path: "/member/find/password",
        component: <PwFind/>,
        type : "notLogin"
    },
    {
        exact: true,
        path: "/set/password",
        component: <SetPassword/>,
        type : "notLogin"
    },
    {
        exact: true,
        path: "/member/company",
        component: <Company/>,
        type : "login"
    },
    {
        exact: true,
        path: "/member/pwSetting",
        component: <PwSetting/>,
        type : "login"
    },
    {
        exact: true,
        path: "/workHistory",
        component: <WorkHistory/>,
        type : "login"
    },
    {
        exact: true,
        path: "/workHistory/modify/request/:id",
        component: <WorkHistoryModifyRequest/>,
        type : "login"
    },
    {
        exact: true,
        path: "/workHistory/modify/detail/:id",
        component: <WorkHistoryModifyDetail/>,
        type : "login"
    },
    {
        exact: true,
        path: "/additional",
        component: <Additional/>,
        type : "login"
    },
    {
        exact: true,
        path: "/additional/request",
        component: <AdditionalRequest/>,
        type : "login"
    },
    {
        exact: true,
        path: "/additional/request/detail/:id",
        component: <AdditionalRequestDetail/>,
        type : "login"
    },
    {
        exact: true,
        path: "/board",
        component: <Board/>,
        type : "login"
    },
    {
        exact: true,
        path: "/board/detail/:id",
        component: <BoardDetail/>,
        type : "login"
    },
    {
        exact: true,
        path: "/board/register",
        component: <BoardRegister/>,
        type : "login"
    },
    {
        exact: true,
        path: "/board/modify/:id",
        component: <BoardModify/>,
        type : "login"
    },
    {
        exact: true,
        path: "/dayoff",
        component: <DayOff/>,
        type : "login"
    },
    {
        exact: true,
        path: "/dayoff/request",
        component: <DayOffRequest/>,
        type : "login"
    },
    {
        exact: true,
        path: "/dayoff/detail/:id",
        component: <DayOffDetail/>,
        type : "login"
    },
    {
        exact: true,
        path: "/tempWorker",
        component: <TempWorker/>,
        type : "login"
    },
    {
        exact: true,
        path: "/tempWorker/register",
        component: <TempWorkerRegister/>,
        type : "login"
    },
    {
        exact: true,
        path: "/tempWorker/modify/:id",
        component: <TempWorkerModify/>,
        type : "login"
    },
    {
        exact: true,
        path: "/document",
        component: <Document/>,
        type : "login"
    },
    {
        exact: true,
        path: "/document/request",
        component: <DocumentRequest/>,
        type : "login"
    },
    {
        exact: true,
        path: "/document/detail/:id",
        component: <DocumentDetail/>,
        type : "login"
    },
    {
        exact: true,
        path: "/document/sign/:id",
        component: <DocumentSign/>,
        type : "login"
    },
    {
        exact: true,
        path: "/schedule",
        component: <ScheduleMain/>,
        type : "login"
    },
    {
        exact: true,
        path: "/workflow",
        component: <Workflow/>,
        type : "login"
    },
    {
        exact: true,
        path: "/todo",
        component: <Todo/>,
        type : "login"
    },
    {
        exact: true,
        path: "/todo/register",
        component: <TodoRegister/>,
        type : "login"
    },
    {
        exact: true,
        path: "/todo/modify/:id",
        component: <TodoModify/>,
        type : "login"
    },
    {
        exact: true,
        path: "/salary",
        component: <Salary/>,
        type : "login"
    },
    {
        exact: true,
        path: "/salary/detail/:id",
        component: <SalaryDetail/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage",
        component: <Mypage/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/password/modify",
        component: <PwModify/>,
        type : "login"
    },
];