import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {BottomErrMsg, LoadingBox, Popup} from "component/basic/popup";
import { ContentsSection, InputSection, PageTitle } from "component/app/items";
import { InputItemBox } from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { CompanyBtn, SnsBtn } from "component/member/items";
import { Header } from "component/elements/header";
import { NotItems } from "component/basic/notItems";

const PwSetting = (props) => {
    const navigate = useNavigate();

    const [email,setEmail] =  useState("");
    const [loadDing,setLoading] =  useState(false);
    const [isSend,setIsSend] =  useState(false);

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [inputErr,setInputErr] =  useState(false);

    function pwSetting(){
        let formData = new FormData();
        formData.append("email", email);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/email/certification",
            loginType: "login",
            success: (data) => {
                setLoading(false);

                setBottomMsgData({
                    text :"비밀번호 재설정을 위한 메일이 발송되었습니다.<br/>작성하신 메일 주소에 맞는 메일함을 확인해주세요.",
                    chk : bottomMsgData.chk + 1
                });


                setTimeout(() => {
                    navigate(`/member/login`);
                }, 1500);
            },
            err: (data) => {
                if(data.data || data.alert){
                    setInputErr(true);
                    setLoading(false);
                    setIsSend(false);
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    window.logOut();
                    navigate(`/member/login`);
                }}
                headTitle="비밀번호 찾기"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <PageTitle
                    contents="비밀번호 찾기를 위해<br/>이메일을 입력해주세요"
                />
                <InputSection>
                    <InputItemBox
                        addClass={inputErr ? "err" : ""}
                        inputName="이메일"
                        placeholder="이메일 입력"
                        value={email}
                        max={null}
                        regexp={null}
                        func={(value)=>{setEmail(value)}}
                    />
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"인증 확인"}
                        disabled={isSend}
                        func={() => {setIsSend(true);setLoading(true);pwSetting()}}
                    />
                </BtnBox>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />

            <LoadingBox openType={loadDing}/>
        </>
    );
};

export default PwSetting;