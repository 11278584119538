import React, {useEffect} from "react";
import { useHistory } from "react-router";
import {useLocation, useNavigate} from "react-router-dom";

const SetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation().search;

    const prams = location.split('&') ? location.split('&') : [];
    const code = prams[0] ? prams[0].split('?code=')[1] : "";

    useEffect(() => {
        if (code) {
            localStorage.setItem("token", code);
            navigate('/member/pwSetting?isFind=1')
            window.location.reload();
        } else {
            navigate('/member/login')
        }
    }, [code]);

    return (<></>);
};

export default SetPassword;